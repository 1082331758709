import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १४ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १४ वा' num='14'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । गौरीपुत्रा तनया । पडतो तुझ्या पाया । भक्तांस तुम्ही रक्षावा ॥ १ ॥
माझी विनवणी ऐक जरा । मोठ्या कानांचे सार्थक करा । धावून यावे सत्वरा । कृपा करावी लेकरा ॥ २ ॥
आरंभ करते लेखणी । सहाय्यभूत व्हावे झणी । ग्रंथ ठरो मुकुटमणी । हीच तुला विनवणी ॥ ३ ॥
हे सिद्धिविनायका । गणेशा बुद्धिदायका । हे सुखकारका दुःखहारका । माझी प्रार्थना ऐका ॥ ४ ॥
कार्य सिद्धीस न्यावे आता । विघ्ने दूर करा आता । अन्यथा बघे मारतील लाथा । चरणी ठेवतो माथा ॥ ५ ॥
जो तुझ्या चरणावर । श्रद्धेने माथा ठेवणार । त्यासच तू आपला करणार । कृपावर्षाव करणार ॥ ६ ॥
गणामहाराज करे प्रार्थना । सहाय्य असू दे ग्रंथलेखना । पूर्ण होवो मनोकामना । आनंद होईल भक्तगणा ॥ ७ ॥
जो तो संकटमुक्तीचा चाहता । संकटे त्यास येता । पदोपदी होतो विनविता । असाच होता बंडुतात्या ॥ ८ ॥
बंडुतात्या सदाचार संपन्न । भक्तीत रमे त्याचे मन । नुसते न करे प्रपंच पोषण । जरी प्रपंचात राहून ॥ ९ ॥
होता स्त्री परिवार युक्त । उदारभाव अंतःकरणात । सदा होता धर्मरत । त्यातच तो होता रमत ॥ १० ॥
भरपूर दानधर्म करावा । त्यात पैसा उधळावा । शिल्लक काहिही न ठेवावा । गुरूवर भार सोपवावा ॥ ११ ॥
ऐसे करता धनधान्य संपले । उपासमारीचे प्रसंग आले । सावकाराचे कर्ज घेतले । व्याजाचे चक्र सुरू झाले ॥ १२ ॥
एकाचे भरते दुसर्‍याचे संपते । ऐसे चक्र सुरू होते । व्याजाने सावकाराचे भरते । बंडुतात्याचे घर रिकामे होते ॥ १३ ॥
एक झोपेत मस्तीत । व्याज मिळवण्यात । दुसरा चिंतेत उदासीनतेत । कर्जाचे व्याज देण्यात ॥ १४ ॥
सावकाराच्या घरात । बंडुतात्या व्याजाचे बी पेरतात । अपार कष्ट करतात । पैशाचे पाणी ओततात ॥ १५ ॥
सावकाराच्या घरी वृक्ष बहरले । पहाता पहाता फोफावले । बंडुतात्याचे पाणी संपले । होते नव्हते ते सर्व गेले ॥ १६ ॥
बंडुतात्याचा जीव सुकला । परी जंगलात पडलेला । कोण पाणी घालणार वृक्षाला । परमेश्वरच घालतो ऐशा वेळेला ॥ १७ ॥
पहाता पहाता दुर्भिक्ष आले । सारे नातेवाईक दुरावले । जीव मेटाकुटीस आले । अफू खाणेही अशक्य झाले ॥ १८ ॥
जीव देण्याचे घाडस होईना । पुढची जाणीव होती मना । जीव घालवण्याचा मार्ग सुचेना । काळ देखिल मदत करेना ॥ १९ ॥
घराकडे पहावेना । घर काही सोडवेना । माघार घेई पुन्हा पुन्हा । पुढे जाण्या तयार होईना ॥ २० ॥
परी घर सोडल्यावाचून । गत्यंतर न पळल्यावाचून । आधी होते नंदनवन । झाले त्याचे स्मशान ॥ २१ ॥
अंगास राख फासता झाला । हिमालयाची वाट धरता झाला । पूर्व पुण्याईस अंकुर फुटला । त्यास एक ब्राह्मण भेटला ॥ २२ ॥
म्हणता झाला त्यास । नको अनुसरू ऐशा कृत्यास । न मुक्त प्रारब्ध भोगास । हेच कारण पुढील जन्मास ॥ २३ ॥
संपवल्याने संपते । टाळल्याने पुढे उभे रहाते । जे जे शिल्लक उरते । पुढील जन्मी भोगणे होते ॥ २४ ॥
आळव सद्गुरूनाथाला । तुझीच काळजी त्याला । तू जावे शेगावला । मुक्त होशील संकटाला ॥ २५ ॥
नको तिकिट हरिद्वारचे । उसन्या वैराग्याचे । तिकिट काढ शेगावचे । भक्तीरसात डुंबण्याचे ॥ २६ ॥
पूर्वपुण्याईचे पीक उभारलेले । जे न कधी दिसलेले । ते आज दिसू लागले । तेणे शेगाव पत्करले ॥ २७ ॥
विनवू लागला समर्थांस । धरले त्याने चरणास । प्रपंची सोसला त्रास । सुखाचा न राहिला घास ॥ २८ ॥
समर्थे दृष्टांत दिला । ब्राह्मणाचा उपदेश ऐकवला । बंडुतात्या थक्क झाला । समर्थांचा अधिकार कळला ॥ २९ ॥
जाणले त्यांच्या अंतर्ज्ञाना । त्यांचे चरण सोडेना । घरी जाणे पसंत करेना । दिसू लागल्या यातना ॥ ३० ॥
घरी परत जाण्यात । बंडुतात्याचे हित । समर्थे जाणले मनात । उगाच नको शेगावात ॥ ३१ ॥
बोलते झाले बंडुतात्यास । जावे आपल्या घरास । आम्ही असू सहाय्यास । येता जाता स्मरल्यास ॥ ३२ ॥
तुझ्या मळ्यात म्हसोबा । त्याच्या पूर्वेस सुवर्ण तोबा तोबा । दडलेले तुझेच बाबा । मारू नकोस उगीच बोंबा ॥ ३३ ॥
मळ्यात रात्री जावे । बाभळी खाली खोदावे । चारशे सुवर्ण मोहोरा मोजावे । सावकारी कर्जातून मुक्त व्हावे ॥ ३४ ॥
मतलबी गुरूआज्ञा पटते । हेच दृष्टोत्पत्तीस येते । बंडुतात्याही तसेच होते । मळ्याकडे झाले जाते ॥ ३५ ॥
बंडुतात्यास रूचले । त्याने तैसे केले । त्याचे दारिद्र्य पार पळाले । मन आनंदे डोलू लागले ॥ ३६ ॥
महाराज महाराज दिसू लागले । मन महाराजमय झाले । सावकाराचे कर्ज फेडले । घरदार सर्व सोडवले ॥ ३७ ॥
बायको पोरांस अलंकार केले । स्वतःस भक्तीचे अलंकार केले । घर पूर्वव्रत झाले । स्मशान नंदनवन झाले ॥ ३८ ॥
सूर्य अस्ताला गेलेला । उदयास आला । जीवनी उषःकाल झाला । एक भक्त सावरला गेला ॥ ३९ ॥
जैसी कृपा बंडुतात्यास । तैसी लाभो तुम्हांस । भाग्य येईल उदयास । लोप उद्विग्नतेस ॥ ४० ॥
शेगावी जरी समर्थ होते । परी गावोगावी फिरत होते । त्यांचे भक्तही हुशार होते । तिथी महात्म्य जाणत होते ॥ ४१ ॥
नर्मदा स्नानाची महती । त्यात अमावस्या सोमवती । सर्व योगात श्रेष्ठ तिथी । भक्त मंडळी जाणत होती ॥ ४२ ॥
नर्मदा स्नानास उत्सुक होती । होण्या पुण्याच्या गाठीभेटी । समर्थ कृपा हवी होती । तेणे संत गजानन सोबती ॥ ४३ ॥
विचार सांगितला समर्थांना । समर्थांना काही पटेना । समर्थ काही बोलेना । आज्ञा संमती देईना ॥ ४४ ॥
मोठी माणसे संमती नसल्यास । धरती मौन व्रतास । हेच आले अनुभवास । समर्थांच्या भक्तांस ॥ ४५ ॥
महाराज ऐसेच होते । पुढचे प्रसंग जाणत होते । स्पष्ट नाही म्हणवत नव्हते । लाडक्यांचे मन मोडवत नव्हते ॥ ४६ ॥
जे प्रत्येक आई बापाचे । तेच झाले समर्थांचे । प्रत्यक्ष नाही म्हणण्याचे । धाडस न झाले त्यांचे ॥ ४७ ॥
तीन हट्ट फार वाईट । बालहट्ट स्त्रीहट्ट राजहट्ट । एकालाही पत्करण्यात । धोकाच जीवनात ॥ ४८ ॥
बालहट्ट भारी पडला । समर्थांवर प्रसंग आला । अगदी अनपेक्षितपणे आला । कुणी न अडवू शकला ॥ ४९ ॥
ओंकारेश्वरी जाण्यास । ईच्छेविरूद्ध जाण्यास । बालमन न दुखवण्यास । जाणे भाग पडले समर्थांस ॥ ५० ॥
पुढचे प्रसंग माहित असताना । टाळता येईना समर्थांना । विधात्यापुढे मान तुकवताना । भाग पडले समर्थांना ॥ ५१ ॥
एक दिवस उजाडला । निघाले ओंकारेश्वराला । प्रसंगाचा प्रहर सुरू झाला । दुसराही होता सोबतीला ॥ ५२ ॥
सर्व निघाले ओंकारेश्वरास । सर्व निघाले नर्मदा स्नानास । पुण्य पदरात पाडण्यास । नर देहाचे सार्थक करण्यास ॥ ५३ ॥
चांगली गोष्ट सुद्धा करण्यास । तोंड द्यावे लागते वाईटास । हे न चुकले कुणास । कैसे चुकेल समर्थांस ? ॥ ५४ ॥
एकेक गाव मागे पडले । जेव्हा दुसरे गाव पुढे आले । पहाता पहाता ओंकारेश्वर आले । सर्व आनंदे डोलू लागले ॥ ५५ ॥
नर्मदेच्या घाटावर । स्त्री पुरूषांची गर्दी फार । दूरदूरचे सर्व येणार । तिर्थस्नान करणार ॥ ५६ ॥
कुणी नदीत उतरले । तिर्थस्नान करू लागले । भक्तीभावाने ओथंबलेले । नरदेहास डुंबवू लागले ॥ ५७ ॥
नाना तर्‍हेचे भक्त । मग्न होते संकल्पात । नाना तर्‍हे आळवण्यात । कुणी नवस फेडण्यात ॥ ५८ ॥
कुणी मग्न अर्घ्य देण्यात । सूर्यनारायण प्रत्यक्षात । गायत्रीमाता साक्षात । येऊदे हो पुढ्यात ॥ ५९ ॥
कुणी नर्मदा महात्म्य स्तवनात । कुणी घरच्या विवंचनेत । कुणी तिर्थप्रसादात । म्हणती नको उशीर त्यात ॥ ६० ॥
तिर्थस्नान उरकून । बिल्वपत्रे घेऊन । मंदिरात जाऊन । ओंकारेश्वरास अर्पण ॥ ६१ ॥
विविध शाखेचे ब्राह्मण । सुवर्ण संधी जाणून । भक्तांस प्रलोभने देऊन । त्याचे महत्व पटवून ॥ ६२ ॥
कुणी कर्तव्य समजून । यथार्थ विधी समजावून । तिर्थ महात्म्य पटवून । करे सार्थक जीवन ॥ ६३ ॥
कुणी पेढे, बर्फी, नारळ । प्रसादास सुकाळ । पोरे बाळे खुशाल । आनंदाची वाटचाल ॥ ६४ ॥
कुणी दंग भजनात । टाळ मृदुंग झांजांत । निनाद सभोवतालात । पडसाद उमटतात ॥ ६५ ॥
नको रे बाळ्या पुढे जाऊ । शोधता शोधता नाकी नऊ । हात नको सोडून जाऊ । परतताना खेळणी घेऊ ॥ ६६ ॥
भक्तांसवे समर्थ । होते ऐशा गर्दीत । परी वेगळ्याच विचारात । न रमले दृष्य सभोवतालात ॥ ६७ ॥
भक्त आणि समर्थ । स्नान उरकून देवळात । गुंतले ओंकारेश्वरात । गुंतले ॐकार नादात ॥ ६८ ॥
ओंकारेश्वराची महती । वदली भक्तांप्रती । गुरफटे भक्तांभोवती । शोभे भोळा नाथ अती ॥ ६९ ॥
व्यवस्थित तिर्थस्नान केले । मनोभावे येणे झाले । मनोभावच व्यक्त केले । भक्तीभाव जागृत केले ॥ ७० ॥
इकडे तिकडे न रमले । इकडे तिकडे न भटकले । खर्‍या अर्थी ते आलेले । खरा अर्थ जाणते झाले ॥ ७१ ॥
भक्त वदती समर्थांना । बैलगाडी नको परतताना । गर्दीतून वाट काढताना । न जमेल त्या बैलांना ॥ ७२ ॥
उगाच उशीर होईल फार । खेडीगाव स्टेशनाचा जरी करार । नको त्यावर आता भार । नावेचाच घ्यावा आधार ॥ ७३ ॥
नावेने जाऊ खेडीगावाला । समर्थांच्या होकाराला । महत्व त्यांच्या संगतीला । तेणे पुढच्या प्रवासाला ॥ ७४ ॥
महाराज काय बोलणार । मुळचाच होता नकार । परी लाडक्यांसाठी होकार । प्रारब्ध न चुकवणार ॥ ७५ ॥
खेडीगाव पावेतो नावेने । ठरले सर्वांचे जाणे । जे जे ठरविले विधात्याने । होणार क्रमाक्रमाने ॥ ७६ ॥
सर्वजण नावेत बसले । परतीचे प्रवास सुरू झाले । येथ पावेतो सुरळीत झाले । भक्तगणही संतोषले ॥ ७७ ॥
विधात्याने वाढलेल्या ताटात । काय वाढलेले आहे त्यात । हे भक्त जाणण्यात । तितुके नव्हते हुशार त्यात ॥ ७८ ॥
जाणत होते तंतोतंत । केवळ एकटेच समर्थ । परी होते त्यांचे मौनव्रत । ही ही विधात्याची करामत ॥ ७९ ॥
नावेने पुढे चालू लागले । सरस पाणी कापू लागले । नावाडीही डोलू लागले । सभोवताली पाहू लागले ॥ ८० ॥
अर्धी वाट सहज सरले । परी न पल्ला गाठले । पुढे जायचे राहिले । तोच विपरित घडले ॥ ८१ ॥
नावाडी जरी पटाईत । सरसर पाणी कापण्यात । पाण्याची दिशा जाणण्यात । पाण्यातील भोवरे जाणण्यात ॥ ८२ ॥
एक लाट उसळली । खाड्‌कन नावेवर आपटली । नाव फेकली गेली । पुन्हा पुन्हा सावरली ॥ ८३ ॥
तीच गत पुन्हा झाली । पुन्हा लाट उसळली । नाव खडकावर आपटली । प्रयत्नांची शिकस्त झाली ॥ ८४ ॥
प्रवाशांस सांगितले । आता प्राणावर बेतले । सर्वजण भयभीत झाले । महाराजांना बिलगले ॥ ८५ ॥
“गण गण गणात बोते”त । समर्थ होते त्यात रत । तल्लीन होणे मूळ स्वभावात । तेच घडून येण्यात ॥ ८६ ॥
नर्मदेने सूर ऐकले । समर्थांस विनविले । दर्शन देण्यास सांगितले । ऐसे नका जाऊ भले ॥ ८७ ॥
सर्वांनी घ्यावे दर्शन । वंचित का रहावे आपण ? । ऐसे तिच्या मनोमन । दिसून आले कृतीतून ॥ ८८ ॥
प्रसंग निमित्त ठरला । नर्मदेने संधीचा फायदा घेतला । अवलीया न भेटे आपणाला । पुन्हा पुन्हा या स्थानाला ॥ ८९ ॥
समर्थांच्या दर्शनास उत्सुकलेली । केव्हा भेटू स्थिती झाली । खळखळात प्रकटली । नावेस पहाती झाली ॥ ९० ॥
तीने घेरले नावेला । पाहिले एका नावेला । वाटे आली प्राण घ्यायला । परी अंदाज सारा चुकला ॥ ९१ ॥
भक्त पुसती महाराजांना । कोण आली हो ह्या क्षणा ? । काय हेतू हो तिच्या मना ? । संकटात टाकले सर्वांना ॥ ९२ ॥
नर्मदा स्वयेच सांगे सर्वांना । मी ओंकाराची कन्या । पहाण्या आले समर्थां । हाच हेतू माझ्या मना ॥ ९३ ॥
निरंतर रहाते पाण्यात । असते सदा ओल्या वस्त्रात । सर्वच न मला जाणतात । असते एखाद्याच्याच भाग्यात ॥ ९४ ॥
पहाता पहाता जी बोलली । क्षणभरात अदृष्य झाली । सर्वांना तीची खूण पटली । नर्मदा तीला संबोधिली ॥ ९५ ॥
नर्मदेने प्राण रक्षावे । नव्हे समर्थांच्या दर्शनास यावे । चुकीचे प्रायश्चित्त मागावे । त्यांचे कृपाशिष मागावे ॥ ९६ ॥
केवढे समर्थांचे सामर्थ्य । जे वाटले होते संकट । तेच आले शरणागत । रक्षिते झाले सर्व भक्त ॥ ९७ ॥
नावेचे हेलकावणे पाहिले । सभोवताली जे जमलेले । तत्क्षणी बोलते झाले । म्हणती आता सर्व बुडाले ॥ ९८ ॥
गुरूसहवासात रक्षण होते । तेणे भक्त हृदय फुलते । कृतकृत्य होत होते । गुणगान गात होते ॥ ९९ ॥
सर्व मंडळी आली शेगावास । वृत्त सांगितले गावास । नर्मदेने वाचविले आम्हास । जाणले गुरुंच्या अधिकारास ॥ १०० ॥
समर्थांच्या दर्शना यावे । नित्य नवे प्रसंग घडावे । भक्तांस नवे न वाटावे । ऐसेच नित्य घडावे ॥ १०१ ॥
ऐसेच येथे चाललेले । एक दिवस ऐसे घडले । सदाशीव वानवळे । मित्रांसह शेगावी आले ॥ १०२ ॥
चित्रकूटच्या माधवनाथाचे । शिष्य होते गुरूनाथाचे । दर्शन घेण्या गजाननाचे । हेतू होते त्यांच्या मनाचे ॥ १०३ ॥
समर्थ भोजनास बसलेले । परी चित्त त्यात न गुंतलेले । सर्व भक्तांस म्हणाले । कुठे आहेत वानवळे ? ॥ १०४ ॥
बोलविले वानवळ्याला । समर्थ वदले त्याला । माधवनाथ येथे जेवला । परी विडा येथेच विसरला ॥ १०५ ॥
हा विडा माधवास द्यावा । आमचा निरोप सांगावा । गजाननाकडे जेवावा । नि विडा येथेच विसरावा ॥ १०६ ॥
वानवळे चकित झाले । पुढे ते चित्रकूटास गेले । माधवनाथास सांगितले । विडा त्यांस देते झाले ॥ १०७ ॥
माधवे शिष्यांस सांगितले । आमचे सांकेतिक चाले । त्यातले मर्म तुम्हा न कळे । ऐसे बोलून वाटेला लावले ॥ १०८ ॥
ऐसे सांकेतिक बोलण्याचे । गणामहाराजाचे आणि गजाननाचे । मधुन मधुन संभाषणाचे । हे ही सर्व योगायोगाचे ॥ १०९ ॥
नामजपे प्राप्त गणामहाराजास । ऐसेच भाग्य येवो उदयास । तुम्हां सर्व भक्तांस । हीच प्रार्थना ईश्वरास ॥ ११० ॥
गणामहाराजाची प्रार्थना । दोघांतला आनंद मिळो सर्वांना । नाम महिमा कळो भक्तांना । नामच उद्धरेल सकळांना ॥ १११ ॥

 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य चतुर्दशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
